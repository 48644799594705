<template>
  <div class="contain">
    <div>
      <img src="../assets/image/no_auth.png" />
      <p>请通过协同门户登录教育培训系统</p>
    </div>
  </div>
</template>

<style lang="less" scoped>
.contain {
  height: calc(100vh - 200px);
  .mixinFlex(center; center);
  text-align: center;
  img {
    width: 517px;
    height: 320px;
  }
  p {
    color: #555;
    font-size: 20px;
    margin: 40px 0 0;
  }
}
</style>